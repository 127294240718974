.bg-purple{
    background-color: rgba(103, 16, 242, 0.126);
}

.link-purple{
    color: rgba(103, 16, 242, 0.704);
}

.bg-blue{
    background-color: rgba(13, 110, 253, 0.126);
}

.link-blue{
    color: rgba(13, 110, 253, 0.704);
}

.bg-indigo{
    background-color: rgba(91, 94, 251, 0.126);
}

.link-indigo{
    color: rgba(91, 94, 251, 0.704);
}

.bg-cyan{
    background-color: rgba(7, 189, 245, 0.126);
}

.link-cyan{
    color: rgba(7, 189, 245, 0.704);
}

.text-prim{
  color: rgb(196, 130, 25);
}

.btn-prim {
    background: rgb(196, 130, 25);
    color: white;
  }
  
  .btn-prim:hover{
    border: 1px solid rgb(196, 130, 25);
    color: rgb(196, 130, 25);
  }
  
  .btn-outline-prim{
    border: 1px solid rgb(196, 130, 25);
    color: rgb(196, 130, 25);
  }
  
  .btn-outline-prim:hover {
    background: rgb(196, 130, 25);
    color: white;
  }
  
  .btn-second{ 
    background-color: #DF6B4B;
    color: white;
  }
  
  .btn-second:hover{
    border: 1px solid #DF6B4B;
    color: #DF6B4B;
  }
  
  .btn-outline-second{ 
    border: 1px solid #DF6B4B;
    color: #DF6B4B;
  }
  
  .btn-outline-second:hover{
    background-color: #DF6B4B;
    color: white;
  }

  .button-purple{
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    color: #5f4aa9;
    background-color: none;
    border: none;
  }
  
  .bg-lime-yellow{
    background-color: #f4efeb;
  }