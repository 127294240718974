.pointer{
  cursor: pointer;
}

.bot-msg{
  background-color: white!important;
  color: black !important;
  font-size: 16px;
  border: 1px solid #EBEBEBB2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
}

.nav-icon{
  color: #ca8524;
  font-size: 24px;
}

.w0{
  width: 0px;
}

.nav-icon-container{
  margin-left: auto;
  margin-right: 20px;
}

.pointer{
  cursor: pointer;
}

.bg-custom{
  background-color: #092635 !important;
  border: 1px solid #E3962A;
}

.white{
  color: white !important;
}

/* .white-sub{
  color: rgb(233, 233, 233) !important;
} */

.btn-newchat{
  background-color: #E3962A !important;
  border-color: #E3962A;
  color: white;
}

.btn-newchat:hover{
  background-color: #ca8524 !important;
  border-color: #ca8524;
  color: white;
}

.chat-item{
  color: rgb(233, 233, 233) !important;
}

.btn-custom{
  background-color: #E3962A !important;
  border-color: #E3962A;
  color: white;
}
.btn-custom:hover{
  color: white !important;
  background-color: #c9821f !important;
}

.human-msg{
  background-color: #DFD6D6B2 !important;
  color: black !important;
  font-size: 16px;
}

.consult-header{
  border: 1px solid #E3962A;

}

.card-custom{
  background-color: #2E2721;
  color: white;
  border: 1px solid #2E2721 !important;
  transition: all 0.5s ease;
}

.card-custom:hover{
  border: 1px solid #D7A14C !important;
}

.w150{
  width: 150px;
}

.logo-shadow{
  filter: drop-shadow(3px 3px 4px rgba(208, 205, 205, 0.7));
}

.color-primary{
  color: #D7A14C !important;
}

.bg-main{
  background-color: #1A1A1A !important;
}

.w200{
  width: 200px;
}

.custom-input{
  background-color: #1A1A1A;
  border: 1px solid #1A1A1A;
  color: white !important;
}

.custom-input:focus{
  background-color: #1A1A1A;
  border: 1px solid #D7A14C;
}

.custom-input::placeholder{
  color: rgba(213, 213, 213, 0.564);
}

.cursor {
  display: inline-block;
  opacity: 1;
  animation: blink 1s step-start infinite;
}
.table{
  --bs-table-bg: white;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}


@media screen and (max-width: 1200px) {
  .menu-right-content {
    display: none !important;
  }
  .mobile-nav-toggler{
    border-radius: 10px;
    padding: 5px;
    line-height: 0px !important;
  }
}

.active-tab{
  animation: tabAnimation 0.5s ease-in-out forwards;
}

@keyframes tabAnimation {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.heading{
  font-weight: 600 !important;
  font-size: 2.5rem !important;
}
.sub-heading{
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}

.w150px{
  width: 150px !important;
  height: auto !important;
}

.w100px{
  width: 100px !important;
  height: auto !important;
}











/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbda7; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9a9a9abd; 
}





.alert-enter {
  opacity: 1;
  transform: translateX(-100%); /* Move the element to the right */
}
.alert-enter-active {
  transform: translateX(0); /* Move the element back to its original position */
  transition: transform 300ms;
}
.alert-exit {
  transform: translateX(0); /* Ensure the element stays in place before exiting */
}
.alert-exit-active {
  transform: translateX(-100%); /* Move the element to the left when exiting */
  transition:  transform 300ms;
}


.shadow-bottom-right{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-body-hover-zoom {
  overflow: hidden;
}

.card-body-hover-zoom img {
  transition: transform 0.3s ease-in-out;
}

.card-body-hover-zoom:hover img {
  transform: scale(1.02);
  opacity: 0.8;
}

















/* CSS from Sensai */
.default-sidebar .category-widget .category-list li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.default-sidebar .category-widget .category-list li:last-child{
  margin-bottom: 0px;
}

.default-sidebar .category-widget .category-list li a{
  position: relative;
  display: inline-block;
  color: #111;
}

.default-sidebar .category-widget .category-list li a:hover{
  padding-left: 17px;
  color: var(--theme-color);
}

.default-sidebar .category-widget .category-list li a:before{
  position: absolute;
  content: '\e925';
  font-family: 'icomoon';
  font-size: 12px;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.default-sidebar .category-widget .category-list li a:hover:before{
  opacity: 1;
}

.blog-sidebar .tags-widget .tags-list{
  position: relative;
  margin: 0px -5px;
}

.blog-sidebar .tags-widget .tags-list li{
  position: relative;
  display: inline-block;
  float: left;
  margin: 0px 5px 10px 5px;
}

.blog-sidebar .tags-widget .tags-list li a{
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 28px;
  color: #6A6A6A;
  background: #F4F4F4;
  padding: 9px 18px;
  border-radius: 55px;
}

.blog-sidebar .tags-widget .tags-list li a:hover{
  color: #fff;
  background: var(--theme-color);
}

.rtl .blog-sidebar .tags-widget .tags-list li{
  float: right;
}

.blog-sidebar .post-widget .post{
  position: relative;
  display: block;
  padding-left: 110px;
  padding-bottom: 35px;
  margin-bottom: 25px;
  border-bottom: 1px solid #E5E5E5;
  min-height: 118px;
}

.blog-sidebar .post-widget .post:last-child{
  border-bottom: none;
  margin-bottom: 0px;
}

.blog-sidebar .post-widget .post .post-thumb{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90px;
  height: 90px;
  border-radius: 10px;
}

.blog-sidebar .post-widget .post .post-thumb img{
  width: 100%;
  border-radius: 10px;
}

.blog-sidebar .post-widget .post h5{
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.blog-sidebar .post-widget .post h5 a{
  display: inline-block;
  color: var(--title-color);
}

.blog-sidebar .post-widget .post h5 a:hover{
  color: var(--theme-color);
}

.blog-sidebar .post-widget .post .post-date{
  position: relative;
  display: block;
  font-size: 15px;
  color: #6A6A6A;
  padding-left: 24px;
}

.blog-sidebar .post-widget .post .post-date i{
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 16px;
  color: var(--theme-color);
}

.rtl .blog-sidebar .post-widget .post{
  padding-left: 0px;
  padding-right: 110px;
}

.rtl .blog-sidebar .post-widget .post .post-thumb{
  left: inherit;
  right: 0px;
}

.rtl .blog-sidebar .post-widget .post .post-date{
  padding-left: 0px;
  padding-right: 24px;
}

.rtl .blog-sidebar .post-widget .post .post-date i{
  left: inherit;
  right: 0px;
}



.recording {
  animation: pulse-animation 2s infinite;
}


@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(191, 25, 61, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(191, 25, 61, 0);
  }
}