@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.poppins{
    font-family: 'Poppins', sans-serif;
}

.w_25{
    width: 25% !important; 
}

.w_50{
    width: 50% !important;
}

.height_100{
    height: 100% !important;
}

.min_height{
    min-height: 100%;
}

.custom_card_for_mockup_trial{
    flex-grow: 1;
    background-color: white;
    width: 30%;
    padding: 1%;
    margin: 1%;
    border: none;
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

.overflow_hidden{
    overflow: hidden !important;
}

.overlay{
    position: fixed; /* Ensures the overlay covers the entire viewport */
    top: 0;
    /* min-height: 100vh; */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
    z-index: 1; /* Ensure the overlay appears above other content */
}
.z_index_3{
    z-index: 0;
}

.z_index_top{
    z-index: 1;
}

.z_index_mid{
    z-index: 2;
}

.z_index_bottom{
    z-index: 3;
}

.border_bottom{
    border-bottom: 1px solid rgba(169, 169, 169, 0.5);
}

.border_top{
    border-top: 1px solid black;
}

.border_chatInput{
    border-top: 2px solid #ededed !important;
}

.ChatInput{
    width: 100%;
    /* background: #edeff9; */
    border-radius: 15px;
    padding: 10px;
    border: 2px solid transparent;
    border: none;
    outline: none;
}

.ChatInput:focus{
    padding: 10px;
    border: 2px solid transparent;
    border: none;
    outline: none;
}

.ChatInputBtn{
    background-color: transparent;
    border: none;
    fill: black;
}

.message_card{
    background-color: #f5f5f5 !important;
}

.font_size_md{
    font-size: medium;
}

.kanit{
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    font-size: 2vw;
}